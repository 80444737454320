///var/www/website2024/192.testproject.work/khonrakgame/khonrakgame/src/Component/PostsSwiper.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, Parallax } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/parallax';
import { Link } from 'react-router-dom';
import '../styles/PostsSwiper.scss'; //

const PostsSwiper = ({ posts }) => {
  // กำหนดค่า slidesPerView ตาม breakpoint
  const slidesPerView = {
    0: 1, // Mobile
    640: 2, // Tablet และใหญ่กว่า
  };

  // ตรวจสอบจำนวน slides และปิด loop หาก slides ไม่พอ
  const enableLoop = posts.length >= 2; // ต้องมีอย่างน้อย 2 slides เพื่อเปิดใช้งาน loop

  // ถ้าไม่มีโพสต์แสดงข้อความ "ไม่มีข้อมูล"
  if (!posts || posts.length === 0) {
    return <p style={{ textAlign: 'center' }}>ไม่มีข้อมูลโพสต์</p>;
  }

  return (
    <div className="posts-swiper-container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay, Parallax]}
        spaceBetween={20} // ระยะห่างระหว่าง slides
        navigation
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        parallax
        loop={enableLoop} // เปิดใช้งาน loop เฉพาะเมื่อ slides เพียงพอ
        speed={800} // ความเร็วในการเปลี่ยน slides
        pagination={{ clickable: true }} // ให้ pagination สามารถกดได้
        breakpoints={{
          0: { slidesPerView: slidesPerView[0] }, // Mobile: แสดง 1 slide
          640: { slidesPerView: slidesPerView[640] }, // Tablet และใหญ่กว่า: แสดง 2 slides
        }}
      >
        {posts.map((post, index) => (
          <SwiperSlide key={`${post.ID}-${index}`}>
            <Link to={`/post/${post.ID}/${post.post_title}`}>
              <div className="swiper-slide-content">
                <img
                  src={post.images?.[0]?.guid || 'https://afaa.website/s/ce99ba.webp'} // รูปภาพโพสต์ หรือรูป Default
                  alt={post.post_title || 'Image'}
                  className="swiper-image"
                />
                <div className="swiper-caption">
                  <h3>{post.post_title}</h3>
                  <p>{post.post_excerpt}</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}

        {/* เพิ่ม slides ซ้ำเมื่อ slides น้อยกว่า 2 */}
        {posts.length === 1 && (
          <SwiperSlide key="duplicate">
            <Link to={`/post/${posts[0].ID}/${posts[0].post_title}`}>
              <div className="swiper-slide-content">
                <img
                  src={posts[0].images?.[0]?.guid || 'https://afaa.website/s/ce99ba.webp'}
                  alt={posts[0].post_title || 'Image'}
                  className="swiper-image"
                />
                <div className="swiper-caption">
                  <h3>{posts[0].post_title}</h3>
                  <p>{posts[0].post_excerpt}</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};

export default PostsSwiper;
