// /var/www/afra/192.testproject.work/khonrakgame/khonrakgame/src/Component/Layout.js

import React from 'react';
import MenubarComponent from './MenubarComponent';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <>
      {/* แสดง MenubarComponent */}
      <MenubarComponent />
      
      {/* แสดงเนื้อหาของแต่ละหน้า */}
      <main>{children}</main>
      
      {/* แสดง Footer */}
      <Footer />
    </>
  );
};

export default Layout;
