import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Card } from 'primereact/card';
import { Paginator } from 'primereact/paginator';
import FrequencyChip from './FrequencyChip';
import '../styles/SearchResults.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SearchResults() {
    const location = useLocation();
    const queryParam = new URLSearchParams(location.search).get('q') || '';

    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [wordFrequency, setWordFrequency] = useState({});
    const [totalCount, setTotalCount] = useState(0);

    // Pagination
    const [first, setFirst] = useState(0);
    const rows = 30;

    // Cache to avoid re-fetch
    const [cache, setCache] = useState({});

    // Reset offset when query changes
    useEffect(() => {
        setFirst(0);
    }, [queryParam]);

    const fetchResults = async () => {
        // If no query, reset
        if (!queryParam) {
            setResults([]);
            setTotalCount(0);
            setLoading(false);
            return;
        }

        setLoading(true);
        const offset = first;
        const cacheKey = `${queryParam}_${offset}`;

        // Check cache first
        if (cache[cacheKey]) {
            setResults(cache[cacheKey].posts);
            setTotalCount(cache[cacheKey].count);
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(
                `https://py.afaa.website/blog/api/game?offset=${offset}&limit=${rows}&order_by=ID&order_direction=DESC&title=${queryParam}&content=${queryParam}`
            );
            const data = await response.json();

            if (data && data.posts) {
                // Count word frequency in post titles
                const allWords = data.posts.flatMap(post =>
                    post.post_title.split(/\s+/)
                );
                const wordCount = array_count_values(allWords);
                const sortedWordCount = arsort(wordCount);

                setWordFrequency(sortedWordCount);
                setResults(data.posts);
                setTotalCount(data.total_count || 0);

                // Save to cache
                setCache(prev => ({
                    ...prev,
                    [cacheKey]: {
                        posts: data.posts,
                        count: data.total_count || 0
                    }
                }));
            } else {
                setResults([]);
                setTotalCount(0);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setResults([]);
            setTotalCount(0);
        } finally {
            setLoading(false);
        }
    };

    // Run fetch when queryParam or first changes
    useEffect(() => {
        fetchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParam, first]);

    function array_count_values(arr) {
        return arr.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
        }, {});
    }

    function arsort(obj) {
        const sortable = Object.entries(obj).sort((a, b) => b[1] - a[1]);
        return Object.fromEntries(sortable);
    }

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const monthNames = [
            'Jan','Feb','Mar','Apr','May','Jun',
            'Jul','Aug','Sep','Oct','Nov','Dec'
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const onPageChange = (e) => {
        setFirst(e.first);
    };

    function decodeQuery(query) {
        return decodeURIComponent(query);
    }

    return (
        <HelmetProvider>
            <div className="search-results-container">
             
                <Helmet>
                    <title>Search Results for: {decodeQuery(queryParam)}</title>
                    <meta name="description" content={`Search results for ${queryParam}`} />
                </Helmet>

                <h4>AA-Chats Search : {decodeQuery(queryParam)}</h4>
                <p className="result-count">{totalCount} results</p>

                {/* แสดงความถี่ของคำในหัวข้อ */}
                <FrequencyChip results={results} wordFrequency={wordFrequency} />

                {loading ? (
                    <div className="spinner-container">
                        <ProgressSpinner />
                    </div>
                ) : (
                    <div className="results-grid">
                        {results.length > 0 ? (
                            results.map((post) => (
                                <div key={post.ID} className="result-item">
                                    <a
                                        href={`https://blog.ttt-website.com/?p=${post.ID}`}
                                        rel="noopener noreferrer"
                                    >
                                        <Card
                                            title={post.post_title}
                                            subTitle={post.post_excerpt}
                                        >
                                            <img
                                                src={
                                                    post.images?.[0]?.guid
                                                        || 'https://afaa.website/s/ce99ba.webp'
                                                }
                                                alt={post.post_title}
                                                onError={(e) => {
                                                    e.target.src =
                                                        'https://afaa.website/s/ce99ba.webp';
                                                }}
                                                className="result-image"
                                            />
                                        </Card>
                                    </a>
                                    <p className="fontx">
                                        {formatDate(post.post_modified)}
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p>No results found for your AA-Chats Search.</p>
                        )}
                    </div>
                )}

                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalCount}
                    onPageChange={onPageChange}
                    rowsPerPageOptions={[30]}
                />
            </div>
        </HelmetProvider>
    );
}

export default SearchResults;
