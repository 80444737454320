import React from 'react';
import { Menubar as PrimeMenubar } from 'primereact/menubar';
import '../styles/MenubarComponent.scss'; // Import SCSS

const MenubarComponent = () => {
  const items = [
    {
      label: 'AA-Chats',
      icon: 'pi pi-fw pi-search',
      command: () => {
        window.location.href = '/search';
      }
    },

    {
      label: 'ข่าวเกม',
      icon: 'pi pi-fw pi-box',
      command: () => {
        window.location.href = 'https://blog.ttt-website.com/game/';
      }
    },

    {
      label: 'ข่าวเทคโนโลยี',
      icon: 'pi pi-fw pi-globe',
      command: () => {
        window.location.href = 'https://blog.ttt-website.com/technology/';
      }
    },
    {
      label: 'ข่าวความปลอดภัย SECURITY',
      icon: 'pi pi-building-columns',
      command: () => {
        window.location.href = 'https://blog.ttt-website.com/category/security/';
      }
    },
    {
      label: 'ข่าวปัญญาประดิษฐ์ AI',
      icon: 'pi pi-fw pi-android',
      command: () => {
        window.location.href = 'https://blog.ttt-website.com/ai/';
      }
    },
    {
      label: 'ข่าว Programming',
      icon: 'pi pi-fw pi-code',
      command: () => {
        window.location.href = 'https://blog.ttt-website.com/category/programming/';
      }
    },

    {
      label: 'บริการของเรา',
      icon: 'pi pi-fw pi-briefcase',
      className: 'custom-dropdown',
      items: [
        {
          label: 'รับพัฒนาระบบ',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://dev.rampagesoft.com/';
          }
        },
        {
          label: 'รับทำ SEO',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://seo.rampagesoft.com/';
          }
        },
        {
          label: 'รับทำเว็บไซต์',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://ttt-website.com/';
          }
        },
        {
          label: 'รับทำ WordPress Elementor Pro',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://ttt-website.com/wordpress/';
          }
        },
        {
          label: 'รับทำ RSS & EGP',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://rss.ttt-website.com/';
          }
        },
        {
          label: 'พัฒนาเว็บไซต์ WEBDESIGN',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://rampagesoft.com/webdesign/';
          }
        },
        {
          label: 'รับพัฒนา CRM & RESERVA',
          icon: 'pi pi-fw pi-star-fill',
          command: () => {
            window.location.href = 'https://rampagesoft.com/webdesign/crm';
          }
        }
      ]
    },

    {
      label: 'Contact',
      icon: 'pi pi-fw pi-phone',
      command: () => {
        window.location.href = 'https://afraapache.com/contact';
      }
    }

  ];

  const logo = (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        window.location.href = '/';
      }}
    >
      <img
        alt="KHONRAKGAME LOGO"
        src="https://afaa.website/s/ce99ba.webp"
        style={{ width: '50px' }}
      />
    </a>
  );

  return (
    <PrimeMenubar
      model={items}
      start={logo}
      className="luxury-menubar" // เชื่อมโยงกับ SCSS
    />
  );
};

export default MenubarComponent;
