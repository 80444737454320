// /var/www/afra/192.testproject.work/khonrakgame/khonrakgame/src/Component/Home.js
import React from 'react';
import PostsSwiper from './PostsSwiper';
import PostsList from './PostsList';
import { ProgressSpinner } from 'primereact/progressspinner';

const Home = ({ posts, totalRecords, first, rows, setFirst, setRows, isLoading }) => {
  return (
    <div className="content">
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <PostsSwiper posts={posts} /> {/* ส่ง posts ให้ Swiper */}
          <PostsList
            posts={posts}
            totalRecords={totalRecords}
            first={first}
            rows={rows}
            setFirst={setFirst}
            setRows={setRows}
          />
        </>
      )}
    </div>
  );
};

export default Home;
