  // /var/www/afra/192.testproject.work/khonrakgame/khonrakgame/src/App.js
  import React, { useState, useEffect } from 'react';
  import { createBrowserRouter, RouterProvider } from 'react-router-dom';

  import 'primereact/resources/themes/saga-blue/theme.css';
  import 'primereact/resources/primereact.min.css';
  import 'primeicons/primeicons.css';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import './styles/App.scss';

  import { HelmetProvider } from 'react-helmet-async';
  import Layout from './Component/Layout'; // Import Layout
  import RedirectToPostWithSlug from './Component/RedirectToPostWithSlug';
  import PostDetails from './Component/PostDetails';
  import SearchPage from './Component/SearchPage';
  import SearchResults from './Component/SearchResults';
  import Home from './Component/Home';

  function App() {
    const [posts, setPosts] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(51);
    const [isLoading, setIsLoading] = useState(false);

    // Fetch ข้อมูลสำหรับการแสดงผล
    useEffect(() => {
      const offset = first;
      setIsLoading(true);

      fetch(`https://py.afaa.website/blog/api/game?offset=${offset}&limit=${rows}&order_by=ID&order_direction=DESC`)
        .then(response => response.json())
        .then(data => {
          setPosts(data.posts || []);
          setTotalRecords(data.total_count || 0);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }, [first, rows]);

    // สร้าง Router และเปิด Future Flags
    const router = createBrowserRouter(
      [
        {
          path: '/',
          element: (
            <Layout>
              <Home
                posts={posts}
                totalRecords={totalRecords}
                first={first}
                rows={rows}
                setFirst={setFirst}
                setRows={setRows}
                isLoading={isLoading}
              />
            </Layout>
          ),
        },
        {
          path: '/post/:id',
          element: (
            <Layout>
              <RedirectToPostWithSlug posts={posts} />
            </Layout>
          ),
        },
        {
          path: '/post/:id/:slug',
          element: (
            <Layout>
              <PostDetails />
            </Layout>
          ),
        },
        {
          path: '/search',
          element: (
            <Layout>
              <SearchPage />
            </Layout>
          ),
        },
        {
          path: '/search/results',
          element: (
            <Layout>
              <SearchResults />
            </Layout>
          ),
        },
      ],
      {
        // เปิดใช้งาน Future Flags
        future: {
          v7_startTransition: true, // ใช้ React.startTransition สำหรับ smooth state updates
          v7_relativeSplatPath: true, // ใช้พฤติกรรมใหม่สำหรับ Splat Routes
        },
      }
    );

    return (
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    );
  }

  export default App;
